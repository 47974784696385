<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <h4 class="text-center">Dados básicos</h4>
      <LineDivider></LineDivider>
      <FormField
        label="Razão Social"
        v-model="form.razaoSocial"
        :validation="$v.form.razaoSocial"
        :loading="isLoading"
      />
      <FormField
        label="Telefone"
        v-model="form.telefone"
        :mask="maskPhone"
        :validation="$v.form.telefone"
        :loading="isLoading"
        personalizedMessage="Telefone inválido"
      />
      <FormField
        label="E-mail"
        typeField="email"
        v-model="form.email"
        :validation="$v.form.email"
        :loading="isLoading"
        :personalizedMessage="emailInvalid"
      />
      <v-row>
        <v-col class="text-center text-md-end">
          <FormButton
            :loading="isLoading"
            type="primario"
            @click="onSubmit()"
            text="Salvar"
          />
        </v-col>
      </v-row>
      <DeploymentMessage :show="showWizard" :step="stepWizard" />
    </Panel>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import LineDivider from '@/components/LineDivider'
import MarketingModules from '@/components/MarketingModules'
import DeploymentMessage from '@/components/DeploymentMessage'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  DADOS_EMPRESA_SAVE_PAYMENT,
  DADOS_EMPRESA_GET
} from '@/store/actions/dadosEmpresa'
import { ADMIN_GET_PASSOS_IMPLANTACAO } from '@/store/actions/admin'
import { required, email, helpers } from 'vuelidate/lib/validators'

export default {
  name: 'InformacoesCobranca',
  components: {
    Panel,
    FormField,
    FormButton,
    LineDivider,
    MarketingModules,
    DeploymentMessage
  },
  data: () => ({
    isLoading: false,
    form: {
      razaoSocial: '',
      telefone: '',
      email: ''
    },
    segmentos: [],
    wizardPath: 'info-cobranca-loja',
    showWizard: '',
    stepWizard: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  validations: {
    form: {
      razaoSocial: { required },
      telefone: {
        customRequired: helpers.withParams(
          { type: 'customRequired' },
          function() {
            const validacao = valor => valor < 10 || valor === 12 || valor > 13
            const qtdTelefone = `${this.form.telefone.replace(/\D/g, '')}`
              .length
            return !validacao(qtdTelefone)
          }
        )
      },
      email: { required, email }
    }
  },
  computed: {
    ...mapGetters(['getFornecedor', 'moduloFornecedorAtivo']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    emailInvalid() {
      return !this.$v.form.email.email
        ? 'Email é inválido'
        : !this.$v.form.email.required
        ? 'Campo obrigatório'
        : ''
    },
    maskPhone() {
      return this.form.telefone &&
        this.form.telefone.replace(/\D/g, '').length > 11
        ? ['(##) ####-####', '(##) #####-####', '## (##) #####-####']
        : ['(##) ####-####', '(##) #####-####']
    }
  },
  methods: {
    getPassosImplantacao() {
      this.isLoading = true
      this.$store
        .dispatch(ADMIN_GET_PASSOS_IMPLANTACAO, {
          execute: `${true}`,
          step: this.wizardPath
        })
        .then(data => {
          this.isLoading = false
          if (data && data[0]) {
            this.stepWizard = data[0]
          }
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha ao buscar os passos de implantação'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    getData() {
      this.isLoading = true
      this.$store
        .dispatch(DADOS_EMPRESA_GET, false)
        .then(data => {
          const telefone = this.formatTelefone(data.telefone)
          this.form = {
            ...this.form,
            razaoSocial: data.razao_social,
            telefone: telefone,
            email: Array.isArray(data.email) ? data.email[0] : data.email
          }
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Não foi possível buscar informações de cobrança'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const data = {
        ...this.form,
        telefone: this.form.telefone.replace(/\D/g, '')
      }
      this.$store
        .dispatch(DADOS_EMPRESA_SAVE_PAYMENT, data)
        .then(() => {
          if (this.getFornecedor.publicado) {
            this.$vueOnToast.pop(
              'success',
              'Informações de cobrança salvas com sucesso.'
            )
          } else {
            this.showWizard = 'sucesso'
            this.getPassosImplantacao()
          }
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha ao atualizar informações de cobrança'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    formatTelefone(phone) {
      const telefone = Array.isArray(phone) ? phone[0] : phone
      if (telefone.length === 10) {
        return `(${telefone.substring(0, 2)}) ${telefone.substring(
          2,
          6
        )}-${telefone.substring(6)}`
      } else if (telefone.length === 11) {
        return `(${telefone.substring(0, 2)}) ${telefone.substring(
          2,
          7
        )}-${telefone.substring(7)}`
      } else if (telefone.length === 13) {
        return `${telefone.substring(0, 2)} (${telefone.substring(
          2,
          4
        )}) ${telefone.substring(4, 9)}-${telefone.substring(9)}`
      }
      return telefone
    }
  },
  created() {
    this.getData()
  }
}
</script>
